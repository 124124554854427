import Vue from 'vue'
import Vuex from 'vuex'
import { elearningStore } from './elearningStore'
import { INITIALIZE_STORE, LOGIN_USER, LOGOUT_USER, SAVE_SEARCH_RESULTS, REFRESH_ACCESS_TOKEN, SHOW_PERMISSION_ERROR, SET_UNREAD_MESSAGES_COUNT } from "./mutation-types"
import jwt_decode from "jwt-decode";
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userId: "",
    userInitials: "",
    profilePicture: "",
    accessToken: "",
    deviceToken: "",
    unReadMessagesCount: "",
    universityName: "VICTORIA UNIVERSITY",
    logoImageUrl: require("../assets/img/vu-logo-with-words.png"),
    backgroundImage: require("../assets/img/cover_photo.jpg"),
    search_results: [],
    userRoles: [],
    // userRoles: ['VC'],
    firstName: "",
    isPesamoniUser: false,
    permissionError: null,
  },

  mutations: {

    [SET_UNREAD_MESSAGES_COUNT]: (state, payload) => {
      state.unReadMessagesCount = payload;
    },
    [SHOW_PERMISSION_ERROR]: (state) => {
      //@ts-ignore
      state.permissionError = +(new Date());
    },
    [LOGIN_USER]: (state, { accessToken, fbDeviceToken }) => {
      //Save the Device Token as we need it for Single User Sessions
      state.deviceToken = fbDeviceToken;

      try {
        //@ts-ignore
        var decoded: String = jwt_decode(accessToken);
        //@ts-ignore
        state.userId = decoded.user_id;
        //@ts-ignore
        state.profilePicture = decoded.pp;
        //@ts-ignore
        state.userInitials = decoded.ui;
        //@ts-ignore
        state.firstName = decoded.fn;
        //@ts-ignore
        state.userRoles = decoded.roles;
        state.accessToken = accessToken;
        //@ts-ignore
        state.isPesamoniUser = decoded.isP;

        //@ts-ignore
        state.fullName = decoded.fn;

        //@ts-ignore

      } catch (error) {
        //Nothing to do
      }

      //Save The Access Token in local Storage 
      (localStorage.getItem("isPersistent") ? localStorage : sessionStorage).setItem("a-at", accessToken);
      (localStorage.getItem("isPersistent") ? localStorage : sessionStorage).setItem("a-dt", fbDeviceToken);
    },
    [REFRESH_ACCESS_TOKEN]: (state, { accessToken }) => {
      //Save The Access Token in local Storage 
      (localStorage.getItem("isPersistent") ? localStorage : sessionStorage).setItem("a-at", accessToken);

      try {
        //@ts-ignore 
        var decoded: String = jwt_decode(accessToken);
        //@ts-ignore
        state.userId = decoded.user_id;
        //@ts-ignore
        state.profilePicture = decoded.pp;
        //@ts-ignore
        state.userInitials = decoded.ui;
        //@ts-ignore
        state.userInitials = decoded.ui;
        //@ts-ignore
        state.firstName = decoded.fn;
        //@ts-ignore
        state.userRoles = decoded.roles;
        state.accessToken = accessToken;
        //@ts-ignore
        state.isPesamoniUser = decoded.isP;

        //@ts-ignore
        state.fullName = decoded.fn;

        //@ts-ignore

      } catch (error) {
        //Nothing to do 
      }
    },
    [LOGOUT_USER]: (state) => {
      //Save The Access Token in local Storage
      state.userId = "";
      state.accessToken = '';
      state.userInitials = '';
      state.profilePicture = '';
      state.firstName = '';
      state.userRoles = [];
      //@ts-ignore
      state.search_results = {};
      (localStorage.getItem("isPersistent") ? localStorage : sessionStorage).removeItem("a-dt");
      (localStorage.getItem("isPersistent") ? localStorage : sessionStorage).removeItem("a-at");
      localStorage.removeItem("isPersistent");
    },
    [INITIALIZE_STORE]: (state) => {
      //Retrieve Device Token
      //@ts-ignore
      state.deviceToken = (localStorage.getItem("isPersistent") ? localStorage : sessionStorage).getItem("a-dt");      //Attempt to retrieve Token from local Storage
      let accessToken = (localStorage.getItem("isPersistent") ? localStorage : sessionStorage).getItem("a-at");
      //If we have no access token, No use to proceed
      if (!accessToken) return localStorage.removeItem("isPersistent");
      try {

        let decoded = jwt_decode(accessToken);
        //@ts-ignore
        state.userId = decoded.user_id;
        state.accessToken = accessToken;

        //@ts-ignore
        state.userInitials = decoded.ui;
        //@ts-ignore
        state.profilePicture = decoded.pp;
        //@ts-ignore
        state.firstName = decoded.fn;
        //@ts-ignore
        state.userRoles = decoded.roles;
        //@ts-ignore
        state.isPesamoniUser = decoded.isP;

        //@ts-ignore
        state.fullName = decoded.fn;

        //@ts-ignore

      } catch (error) {
        //Nothing to do
        //TODO: HANDLE THIS SCENARIO
      }
    },
    [SAVE_SEARCH_RESULTS]: (state, payload) => {
      state.search_results = payload;
    }
  },

  getters: {
    isLoggedIn: state => {
      return !!state.userId;
    },
  },

  plugins: [elearningStore],
  actions: {
  },
  modules: {
  }
})